import axios from 'axios'
import cookies from 'js-cookie'
import router from '@/router'
import { Message } from 'element-ui'

import i18n from '@/components/lang'




/**
 * 请求拦截 加入 Authorization
 */
axios.interceptors.request.use(config => {
    let auth = cookies.get('auth')
    if (auth) {
        config.headers['Authorization'] = auth
    }
    if (config && config.params) {
        if (config.params.addTime) {
            delete config.params.addTime;
        }
        if (config.params.updateTime) {
            delete config.params.updateTime;
        }
    }
    return config;
}, error => {
    return Promise.reject(error);
});

/**
 * 返回拦截，返回code不等于0都报失败
 */
axios.interceptors.response.use(response => {
    let res = response.data;
    if (res.code !== '200') {
        if (res.msg) {
            let msg = res.msg;
            if (i18n.locale === 'zh') {
                let t = i18n.messages[i18n.locale]['error']['e' + res.code];
                if (t) {
                    msg = t;
                }
            }
            if (res.code != '903') {
                Message({
                    message: msg,
                    type: 'error',
                    duration: 3 * 1000
                })
            }

        }
        if (res.code == '903') {
            router.replace('/login');
        } else if (res.code == '404') {
            router.replace('/404');
        }
        return Promise.reject(res)
    } else {
        return res;
    }
}, error => {
    Message({
        message: error.message ? error.message : error.msg,
        type: 'error',
        duration: 3 * 1000
    })
    return Promise.reject(error)
});

export default axios