<template>
  <div class="box">

    <el-row :gutter="20">
        <el-aside class="nav" style="  width: 180px;">
          <el-menu class="el-menu-vertical-demo" :default-active="activeAnchor" @select="handleMenuSelect"  >
            <el-menu-item v-for="anchor in tableList" :key="anchor.id" :index="anchor.id" style="padding:0px">
              {{ anchor.title }}（{{anchor.rows && anchor.rows.length || 0}}）
            </el-menu-item>
          </el-menu>
        </el-aside>
      <el-col :span="22" :xs="24">
        <div class="crumbs">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item>
              <i class="el-icon-date"></i> {{$t('navbar.equipmentInfo')}}
            </el-breadcrumb-item>
            <el-breadcrumb-item>{{$t('navbar.equipment')}}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="container">
          <el-select class="filter-item" style="width: 160px" v-model="queryData.terminalId" placeholder="终端业务线">
            <el-option v-for="item in terminalList" :key="item.id" :label="`${item.id}-${item.terminalName}`" :value="item.id">
            </el-option>
          </el-select>
          <el-input style="width: 160px" clearable class="filter-item" placeholder="请输入手机号码" v-model="queryData.userName">
          </el-input>
          <el-button class="filter-item" type="primary" icon="el-icon-search" @click="handleList()">查询</el-button>
          <div v-for="(item,index) in tableList" :key="index" class="surface anchor"  :id="item.id">
            <div class="title">{{item.title}}</div>
            <el-table :data="item.rows" v-loading="listLoading" :element-loading-text="$t('table.loading')" border size="mini" :stripe="true" highlight-current-row>
              <el-table-column align="center" v-for="(item,index) in item.columns" :key="index" :label="item" :width="['smscontent','currPageParams','userAgent'].includes(item)?'500px':''">
                <template slot-scope="scope">
                  <span>{{scope.row[item] }}</span>
                </template>
              </el-table-column>
              <!-- <af-table-column v-for="(key,index) in item.columns" :key="index" :label="key" :prop="key" >
              </af-table-column> -->
            </el-table>
          </div>
        </div>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { queryEquipmentList, splitterStageRuleRelLimitOptions } from "@/api/request";
const terminalMap = new Map();
export default {
  name: "equipment",
  data() {
    return {
      listLoading: false,
      queryData: { terminalId: '', userName: '' },
      columns: [],
      tableList: [],
      terminalList: [],
      activeAnchor: "",
    };
  },
  created() {
    // this.handleEvents()
  },
  methods: {
    handleEvents() {
      this.listLoading = true
      queryEquipmentList("get", this.queryData).then(res => {
        this.listLoading = false
        this.tableList = res.data.detailList.map((obj, index) => ({ ...obj, id: `anchor${index + 1}` }))
        console.log(this.tableList)
      }).catch(err => {
        this.listLoading = false
        this.tableList = []
      })
    },

    handleList() {
      if (!this.queryData.userName) {
        this.$message.error('请输入手机号码');
        return
      }
      this.handleEvents()

    },

    async getOptions(terminalId) {
      return await splitterStageRuleRelLimitOptions(terminalId);
    },
    handleMenuSelect(index) {
      this.activeAnchor = index;
      const element = document.getElementById(index);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    },
    handleScroll() {
      const anchors = document.querySelectorAll(".anchor");
      for (let i = 0; i < anchors.length; i++) {
        const anchor = anchors[i];
        const rect = anchor.getBoundingClientRect();
        console.log(rect.top,rect.bottom)
        if (rect.top <= 100 && rect.bottom >= 100) {
          this.activeAnchor = anchor.id;
          break;
        }
      }
    }
  },
  mounted() {
    this.getOptions().then(res => {
      this.queryData.terminalId = res.data[0].id
      this.terminalList = res.data;
      this.terminalList.forEach(e => terminalMap.set(e.id, e));
    });
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
};
</script>
<style scoped>
.surface {
  margin-bottom: 60px;
}
.title {
  font-weight: 700;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: rgba(0, 0, 0, 0.85);
}
.nav {
 overflow: hidden;
  position: fixed;
  z-index: 999;
  right: 20px;
  border-radius: 5px;
  text-align: center;
  position: fixed;
    right: 14px;
    top: 100px;
  
    
}
.nav .el-menu {
  background: hsla(0, 0%, 42%, 0.7);
}
.nav .el-menu-item, .el-submenu__title{
  height: 40px;
  line-height: 40px;

}

</style>
<style>
.box .el-table th{
  user-select: inherit;
}
</style>
