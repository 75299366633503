import axios from "./axios";

const _base_url = process.env.NODE_ENV === "development" ? "/api" : window.location.origin + "/mapi";
console.log(process.env, "--process.env");
// 测试 bak
export function test(method, params) {
  return new Promise((resolve, reject) => {
    resolve();
  });
}

// ================================================== 公共接口 ==================================================
// 注册
export function register(user) {
  return axios({
    url: _base_url + "/register",
    method: "post",
    params: user,
  });
}
// 登录
export function login(user) {
  return axios({
    url: _base_url + "/login",
    method: "get",
    params: user,
  });
}
// 登出
export function logout() {
  return axios({
    url: _base_url + "/logout",
    method: "get",
  });
}
// 用户信息和菜单列表
export function info() {
  return axios({
    url: _base_url + "/info",
    method: "get",
  });
}

// ================================================== 公共接口 ==================================================

// ================================================== 系统设置 ==================================================
// 用户
export function user(method, params) {
  let data = Object.assign({}, params);
  if (data && data.roles) {
    data.roleIds = data.roles.join(",");
  }
  delete data.roles;
  return axios({
    url: _base_url + "/admin/sysUser",
    method: method,
    params: data,
  });
}
export function updateInfo(params) {
  return axios({
    url: _base_url + "/admin/sysUser/updateInfo",
    method: "patch",
    params: params,
  });
}
//用户角色列表
export function forUser(method, params) {
  let data = Object.assign({}, params);
  if (data && data.roles) {
    data.roleIds = data.roles.join(",");
  }
  delete data.roles;
  return axios({
    url: _base_url + "/admin/sysRole/forUser",
    method: method,
    params: data,
  });
}
// 角色
export function role(method, params) {
  let data = Object.assign({}, params);
  if (data && data.permissions) {
    data.permissionIds = data.permissions.join(",");
  }
  delete data.permissions;
  return axios({
    url: _base_url + "/admin/sysRole",
    method: method,
    params: data,
  });
}
// 角色权限列表
export function forrole() {
  return axios({
    url: _base_url + "/admin/sysPermission/forUser",
    method: "get",
  });
}

// 权限
export function permission(method, params) {
  return axios({
    url: _base_url + "/admin/sysPermission",
    method: method,
    params: params,
  });
}
// ==========================================================策略配置======================================
//更新策略配置
export function PatchStrategyConfig(method, params) {
  return axios({
    url: _base_url + "/manage/strategyConfig",
    method: method,
    params: params,
  });
}

export function messageRecord(method, params) {
  return axios({
    url: _base_url + "/manage/messageRecord",
    method: method,
    params: params,
  });
}

export function strategyDelay(method, params) {
  return axios({
    url: _base_url + "/manage/strategyDelay",
    method: method,
    params: params,
  });
}

export function strategyConfigAll() {
  return axios({
    url: _base_url + "/manage/strategyConfig/all",
    method: "GET",
  });
}

export function messageEventAll() {
  return axios({
    url: _base_url + "/manage/messageEvent/all",
    method: "GET",
  });
}

// 分流规则关联配置-日限量
export function splitterStageRuleRelLimitApi(method, params) {
  return axios({
    url: _base_url + "/manage/splitterStageRuleRel/limit",
    method: method,
    params: params,
  });
}

// 分流规则关联配置-前置拦截
export function splitterStageRuleRelSafeApi(method, params) {
  return axios({
    url: _base_url + "/manage/splitterStageRuleRel/safe",
    method: method,
    params: params,
  });
}
// 分流规则关联配置选项列表-日限量
export function splitterStageRuleRelLimitOptions(terminalId) {
  return axios({
    url: _base_url + "/manage/splitterStageRuleRel/options/limit",
    method: "GET",
    params: { terminalId: terminalId },
  });
}
// 分流规则关联配置选项列表-前置拦截
export function splitterStageRuleRelSafeOptions(terminalId) {
  return axios({
    url: _base_url + "/manage/splitterStageRuleRel/options/safe",
    method: "GET",
    params: { terminalId: terminalId },
  });
}
// 分流规则关联配置-刷新缓存
export function refreshCache() {
  return axios({
    url: _base_url + "/manage/splitterStageRuleRel/refreshCache",
    method: "GET",
  });
}
// ==========================================================数据查询======================================
//用户信息
export function queryUserIfnfoList(method, params) {
  return axios({
    url: _base_url + "/manage/credit/getUserInfo",
    method: method,
    params: params,
  });
}
// 提现申请记录
export function querywithdrawalListList(method, params) {
  return axios({
    url: _base_url + "/manage/credit/getWithdrawApplyList",
    method: method,
    params: params,
  });
}
// 账单信息
export function queryBillList(method, params) {
  return axios({
    url: _base_url + "/manage/credit/getBillInfoList",
    method: method,
    params: params,
  });
}
// 分流推荐结果
export function queryshuntList(method, params) {
  return axios({
    url: _base_url + "/manage/credit/getSplitterMatchResult",
    method: method,
    params: params,
  });
}
// 用户公共资料
export function queryCommonInfoList(method, params) {
  return axios({
    url: _base_url + "/manage/credit/getUserCommonInfo",
    method: method,
    params: params,
  });
}
// ==========================================================数据查询======================================

// 设备信息查询
export function queryEquipmentList(method, params) {
  return axios({
    url: _base_url + "/manage/riskData/queryDeviceInfoDetails",
    method: method,
    params: params,
  });
}

// ==========================================================渠道额度确认模式配置======================================

// 查询渠道额度确认模式配置列表
export function channelAmountConfirmMode(method, query) {
  return axios({
    url: _base_url + "/manage/channelAmountConfirmMode",
    method: method,
    params: query,
  });
}

// ==========================================================API特殊产品分流配置======================================

// 查询渠PI特殊产品分流列表
export function productSplitterGlobalConfig(method, query) {
  return axios({
    url: _base_url + "/manage/productGlobalSplitterConfig",
    method: method,
    params: query,
  });
}
